body {
  @apply w-full min-h-full overflow-x-hidden antialiased font-primary;
  min-width: 300px;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  @apply flex flex-col w-full min-h-screen;
}

a {
  @apply outline-none;
}

input[type],
textarea {
  @apply bg-clip-padding caret-sbtviolet-550;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  @apply hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @apply bg-white;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

@supports (-webkit-touch-callout: none) {
  input,
  textarea,
  select {
    @apply text-base;
  }
}
