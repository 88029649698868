#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply fixed top-0 left-0 w-full h-1 rounded bg-yellow-50 z-above-all;
}

#nprogress .peg {
  @apply absolute right-0 block h-full rounded opacity-100;
  box-shadow: 0 0 10px theme('colors.yellow.50'), 0 0 5px theme('colors.yellow.50');
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
}

#nprogress .spinner {
  @apply hidden;
}

.nprogress-custom-parent {
  @apply relative overflow-hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  @apply absolute;
}