.fill-current {
  fill: currentColor;
}

.frozen {
  @apply overflow-hidden;
}

@supports (-webkit-touch-callout: none) {
  .frozen {
    @apply fixed;
  }
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.box {
  @apply w-full px-4 mx-auto max-w-screen-2xl 2sm:px-8 xl:px-16 3xl:px-0;
}

.paded-layout {
  @apply px-4 2sm:px-8 xl:px-16 2xl:px-[calc((100%-1680px+8rem)/2)] 3xl:px-[calc((100%-1680px)/2)] relative overflow-hidden;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply w-1 rounded-xl;
}

.custom-scrollbar::-webkit-scrollbar-track {
  @apply rounded-xl bg-sbtviolet-200/50;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-sbtviolet-400 rounded-xl;
}

.custom-scrollbar:focus {
  @apply outline-none;
}