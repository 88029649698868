.ql-editor {
  font-family: 'Poppins' !important;
  /* margin: -15px -15px; */
  padding: 0 !important;
  overflow: hidden !important;
  font-size: 14px;
}
.ql-editor p {
  line-height: 20px;
}
.ql-editor > ul,
.ql-editor > ol {
  padding-left: 0 !important;
}
